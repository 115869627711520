import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const initiatePayment = (payload) => {
    return doPrivatePostRequest(payload, 'transactions/initiate-payment');
}

export const verifyPayment = (payload) => {
    return doPrivatePostRequest(payload, 'transactions/verify-payment');
}

export const refundPayment = (payload) => {
    return doPrivatePostRequest(payload, 'transactions/refund-payment');
}

export const createTransactions = (payload) => {
    return doPrivatePostRequest(payload, 'transactions/create')
}

export const updateTransactions = (payload, doc_id) => {
    return doPrivatePostRequest(payload, `transactions/update/${doc_id}`)
}

export const getTransactions = (_doc_id) => {
    return doPrivateGetRequest(`transactions/get/${_doc_id}`)
}

export const getAllTransactions = () => {
    return doPrivateGetRequest('transactions')
}

export const deleteTransactions = (_doc_id) => {
    return doPrivateDelRequest(`transactions/delete/${_doc_id}`)
}
