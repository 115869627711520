import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, Pagination, ButtonGroup, Form } from '@themesberg/react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ITEMS_PER_PAGE = 10;

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const BrandsTables = ({ brands, deleteBrands, handleEditBrand }) => {
  const [currentPage, setCurrentPage] = useState(1);

  function truncateText(text, maxWords) {
    const wordsArray = text.split(' ');
    if (wordsArray.length > maxWords) {
      return `${wordsArray.slice(0, maxWords).join(' ')}...`;
    }
    return text;
  }

  const [filters, setFilters] = useState({
    "Brand Name": "",
    "Brand Location": "",
    "Linked User": ""
  });

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const currentTableData = () => {
    const filteredData = brands.filter(brand =>
      Object.entries(filters).every(([key, value]) => {
        if (key === "Linked User") {
          return brand.linked_user && brand.linked_user.some(user => user.email?.toLowerCase().includes(value.toLowerCase()));
        }
        const brandValue = brand[key.toLowerCase().replace(" ", "_")];
        return typeof brandValue === 'string' && brandValue?.toLowerCase().includes(value.toLowerCase());
      })
    );

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredData.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(brands.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const TableRow = (props) => {
    const { _id, brand_name, brand_logo, brand_image, cta, founded, slug, meta, brand_location, linked_user, brand_description, approved } = props;

    return (
      <tr>
        <td>
          {meta && meta.length > 0 ? (
            meta.map((item, index) => (
              <img key={index} src={item.brand_logo} height="50px" width="50px" />
            ))
          ) : (
            "No logos available"
          )}
        </td>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditBrand({ _id, brand_name, brand_image, cta, founded, slug, meta, brand_location, linked_user, brand_description })}>
            <div className="title-col">
              <span className="h6">{brand_name}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {approved}
        </td>
        <td className="fw-bold border-0">
          {brand_location ? brand_location : "-"}
        </td>
        <td className="fw-bold border-0">
          {brand_description ? truncateText(brand_description, 10) : "-"}
        </td>
        <td className="border-0">
          {linked_user && linked_user.length > 0 ? (
            linked_user.map((user, index) => (
              <div key={index}>{user.email}</div>
            ))
          ) : (
            "-"
          )}
        </td>
        <td className="freeze-column">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditBrand({ _id, brand_name, brand_image, cta, founded, slug, meta, brand_location, linked_user, brand_description })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteBrands(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Thumbnail</th>
              <th className="border-0">Brand Name</th>
              <th className="border-0">Status</th>
              <th className="border-0">Brand Location</th>
              <th className="border-0">Brand Description</th>
              <th className="border-0">Linked User</th>
              <th className="freeze-column border-bottom">Action</th>
            </tr>
            <tr>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Search by Brand Name"
                  value={filters["Brand Name"]}
                  onChange={(e) => handleFilterChange("Brand Name", e.target.value)}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Search by Location"
                  value={filters["Brand Location"]}
                  onChange={(e) => handleFilterChange("Brand Location", e.target.value)}
                  className="mt-2"
                />
              </th>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Search by Linked User"
                  value={filters["Linked User"]}
                  onChange={(e) => handleFilterChange("Linked User", e.target.value)}
                  className="mt-2"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {brands.length > 0 ? (
              currentTableData().map(brand => <TableRow key={`brand-${brand._id}`} {...brand} />)
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No records could be found at this time. Create your first brand by clicking Add Brand
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="mt-4 justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};
