import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Card, Table, Dropdown, ButtonGroup, Pagination, Button, Form } from '@themesberg/react-bootstrap';
import TransactionDetailsModal from "../modals/TransactionDetailsModal";

export const TransactionsTable = ({ transactions }) => {
  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    packageName: "",
    transactionDate: "",
    paymentStatus: "",
    amount: "",
  });

  const [selectedTransaction, setSelectedTransaction] = useState(null); // State to store the selected transaction
  const [showModal, setShowModal] = useState(false);  // Modal visibility state
  const [selectedTransactions, setSelectedTransactions] = useState([]); // State to store selected transactions

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleCheckboxChange = (transactionId) => {
    setSelectedTransactions((prevSelected) => {
      if (prevSelected.includes(transactionId)) {
        return prevSelected.filter(id => id !== transactionId);
      } else {
        return [...prevSelected, transactionId];
      }
    });
  };

  const handleViewClick = (transaction) => {
    setSelectedTransaction(transaction);  // Set the selected transaction
    setShowModal(true);  // Show the modal
  };

  const handleCloseModal = () => setShowModal(false);  // Close modal function

  const handlePayClick = () => {
    // Implement your Pay logic here for selectedTransactions
    console.log("Pay clicked for transactions:", selectedTransactions);
  };

  const handleDownloadInvoiceClick = () => {
    // Implement your Download Invoice logic here for selectedTransactions
    console.log("Download Invoice clicked for transactions:", selectedTransactions);
  };

  // Apply filters to transactions
  const filteredTransactions = transactions.filter(transaction => {
    return (
      (filters.packageName === "" || transaction.package_name?.toLowerCase().includes(filters.packageName.toLowerCase())) &&
      (filters.transactionDate === "" || transaction.transaction_date?.toLowerCase().includes(filters.transactionDate.toLowerCase())) &&
      (filters.paymentStatus === "" || transaction.payment_status?.toLowerCase().includes(filters.paymentStatus.toLowerCase())) &&
      (filters.amount === "" || transaction.amount?.toString().toLowerCase().includes(filters.amount.toLowerCase()))
    );
  });

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredTransactions.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredTransactions.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const TableRow = ({ _id, package_name, is_annual, amount, total_addons_price, transaction_date, payment_status, payment_gateway_response }) => {
    return (
      <tr>
        <td>
          <Form.Check 
            type="checkbox" 
            checked={selectedTransactions.includes(_id)} 
            onChange={() => handleCheckboxChange(_id)} 
          />
        </td>
        <td>{package_name}</td>
        <td>{is_annual ? 'Yes' : 'No'}</td>
        <td>{amount}</td>
        <td>{total_addons_price}</td>
        <td>{transaction_date}</td>
        <td>{payment_status}</td>
        <td>{payment_gateway_response?.transaction_id}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleViewClick({ _id, package_name, transaction_date, payment_status, amount, is_annual, addons: {}, total_addons_price, package_price: '', currency: '', payment_gateway: '', payment_gateway_response })}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <div className="d-flex justify-content-end mb-3">
            {selectedTransactions.length > 0 && (
              <>
                <Button variant="primary" className="me-2" onClick={handlePayClick}>Pay</Button>
                <Button variant="secondary" onClick={handleDownloadInvoiceClick}>Download Invoice</Button>
              </>
            )}
          </div>
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th><Form.Check type="checkbox" onChange={(e) => setSelectedTransactions(e.target.checked ? filteredTransactions.map(tx => tx._id) : [])} /></th>
                <th>Package Name</th>
                <th>Annual</th>
                <th>Amount</th>
                <th>Total Add-ons Price</th>
                <th>Transaction Date</th>
                <th>Payment Status</th>
                <th>Transaction ID</th>
                <th>Action</th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Package Name"
                    name="packageName"
                    value={filters.packageName}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th></th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Amount"
                    name="amount"
                    value={filters.amount}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th></th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Transaction Date"
                    name="transactionDate"
                    value={filters.transactionDate}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Payment Status"
                    name="paymentStatus"
                    value={filters.paymentStatus}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredTransactions.length > 0 ? (
                currentTableData().map(transaction => (
                  <TableRow key={`transaction-${transaction._id}`} {...transaction} />
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">No transactions found.</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination className="mt-4 justify-content-center">{items}</Pagination>
        </Card.Body>
      </Card>

      {/* Transaction Details Modal */}
      <TransactionDetailsModal
        show={showModal}
        onClose={handleCloseModal}
        transaction={selectedTransaction}
      />
    </>
  );
};
