import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createOfferings = (_offering, _rsService, _maintRepairs, _trainProgram, _spareParts, _emergService, _techSolutions, _refurbSolutions, _custSolutions, _compCertification, _warrOffered, _notes ) => {

    const payload = {
            'offering': _offering,
            'rental_service': _rsService,
            'maintenance_and_repairs': _maintRepairs,
            'training_programmings': _trainProgram,
            'spare_parts_and_accessories': _spareParts,
            'emergency_services_and_response': _emergService,
            'technology_solutions': _techSolutions,
            'refurbished_solutions': _refurbSolutions,
            'customized_solutions': _custSolutions,
            'compliance_and_certification_services': _compCertification,
            'warranty_offered': _warrOffered,
            'notes': _notes   
    }

    return doPrivatePostRequest(payload, '/offerings/create')
}

export const updateOfferings = () => {

    const payload = {}

    return doPrivatePostRequest(payload, '/offerings/update')
}

export const getOfferings = (_doc_id) => {
    return doPrivatePostRequest(`offerings/get/${_doc_id}`)
}

export const getAllOfferings = () => {
    return doPrivatePostRequest('offerings')
}

export const deleteOfferings = (_doc_id) => {
    return doPrivateDelRequest(`offerings/delete/${_doc_id}`)
}
