import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Routes } from './routes';
import Signin from './pages/admin/auth/Signin';
import Presentation from './pages/HomePage';
import './scss/volt.scss';
import 'react-datetime/css/react-datetime.css';
import ScrollToTop from './components/ScrollToTop';
import { UserRoleProvider } from './contexts/UserRoleContext';
import Signout from './pages/admin/auth/Signout'; // Corrected casing
import AuthenticatedRoute from './components/AuthenticatedRoute'; // Import the HOC

const AppContent = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const location = useLocation(); // This is wrapped within BrowserRouter

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedin');
    if (loggedIn) {
      setIsSignedIn(loggedIn === 'true');
    }
  }, []);

  const handleSignIn = () => {
    localStorage.setItem('loggedin', 'true');
    setIsSignedIn(true);
  };

  const handleSignOut = () => {
    localStorage.removeItem('loggedin');
    setIsSignedIn(false);
  };

  if (!location?.pathname) {
    console.error("location.pathname is undefined");
    return null; // Prevent further rendering until location is available
  }

  return (
    <UserRoleProvider>
      <ScrollToTop />
      <Switch>
        {/* Public Routes */}
        <Route path={Routes.Signin.path} render={() => <Signin onSignIn={handleSignIn} />} />
        <Route path={Routes.Presentation.path} component={Presentation} />

        {/* Admin Routes */}
        <AuthenticatedRoute path="/admin" component={Signout} isSignedIn={isSignedIn} />

        {/* Fallback to presentation if the root path is accessed */}
        {location.pathname === '/' && <Redirect to={Routes.Presentation.path} />}

        {/* Add a fallback Redirect if no other routes are matched */}
        <Redirect to={isSignedIn ? Routes.Presentation.path : Routes.Signin.path} />
      </Switch>
    </UserRoleProvider>
  );
};

const App = () => (
  <BrowserRouter>
    <AppContent />
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById('root'));
