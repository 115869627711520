import React, { useState, useEffect } from "react";
import { Dropdown, Image, Button, Container, Nav, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChartPie, faEnvelopeOpen, faSignOutAlt, faUserCircle, faUserShield } from "@fortawesome/free-solid-svg-icons";
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";
import { Routes } from "../../routes";

export const NavigationBar = () => {
    const [signedIn, setIsSignedIn] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        const loggedIn = localStorage.getItem('loggedin');
        if (loggedIn === 'true') {
            setIsSignedIn(true);
            const userEmail = localStorage.getItem('user_email');
            setUserEmail(userEmail);
        } else {
            setIsSignedIn(false);
        }
    }, []);  // Add empty array to run effect only once when component mounts

    const handleSignOut = () => {
        localStorage.removeItem('loggedin');
        localStorage.removeItem('user_email');
        setIsSignedIn(false);
    };

    const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

    return (
        <>
            {/* Mobile Header */}
            <Navbar bg="primary" className="fixed-top d-md-none py-4 mobile-header">
                <Container className="d-flex justify-content-between align-items-center">
                    <Navbar.Brand as={Link} to={Routes.Presentation.path}>
                        <img src="../../assets/img/Forklifting.co-Logo1.png" height="40" alt="Logo" />
                    </Navbar.Brand>
                    <Button variant="link" className="text-white p-0" onClick={toggleOffcanvas}>
                        <FontAwesomeIcon icon={faBars} size="xl" />
                    </Button>
                </Container>
            </Navbar>

            {/* Desktop Header */}
            <section className="bg-primary d-none d-md-block" style={{ height: "40px" }}>
                <Container className="d-flex align-items-center text-white justify-content-between">
                    {signedIn ? (
                        <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                                <div className="media d-flex align-items-center">
                                    <img src={Profile3} className="frontend-user-avatar sm-avatar rounded-circle" />
                                    <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                                        <span className="mb-0 font-small fw-bold">Welcome back, {userEmail}</span>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="text-dark user-dropdown frontend-user-dropdown dropdown-menu-right mt-0">
                                <Dropdown.Item className="fw-bold">
                                    <FontAwesomeIcon icon={faChartPie} className="me-2" /> <Link to={Routes.DashboardOverview.path}>Dashboard</Link>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">
                                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> <Link to={Routes.Settings.path}>Profile Settings</Link>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">
                                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> <Link to={Routes.Inbox.path}>Inbox</Link>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">
                                    <FontAwesomeIcon icon={faUserShield} className="me-2" /> <Link to={Routes.Contact.path}>Support</Link>
                                </Dropdown.Item>

                                <Dropdown.Divider />

                                <Dropdown.Item className="fw-bold" onClick={handleSignOut}>
                                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : null}

                    <Nav className="navbar-nav-hover flex-end">
                        <Nav.Link as={Link} to={Routes.About.path}>About</Nav.Link>
                        <Nav.Link as={Link} to={Routes.Insights.path}>Insights</Nav.Link>
                        <Nav.Link as={Link} to={Routes.Careers.path}>Careers</Nav.Link>
                        <Nav.Link as={Link} to={Routes.FrequentlyAsked.path}>FAQ</Nav.Link>
                        <Nav.Link as={Link} to={Routes.ForSuppliers.path}>For Suppliers</Nav.Link>
                    </Nav>
                </Container>
            </section>

            <Navbar variant="white" expand="lg" bg="white" className="navbar-transparent navbar-theme-primary sticky-top text-dark py-0 d-none d-md-flex">
                <Container className="position-relative justify-content-between px-3">
                    <Navbar.Brand as={Link} to={Routes.Presentation.path} className="me-lg-3 d-flex align-items-center">
                        <span className="ms-2 brand-text d-none d-md-inline">
                            <img src="../../assets/img/Forklifting logo - dark on white background.png" />
                        </span>
                    </Navbar.Brand>
                    <div className="d-flex align-items-center">
                        <Navbar.Collapse id="navbar-default-primary">
                            <Nav className="navbar-nav-hover align-items-lg-center">
                                <Nav.Link as={Link} to={Routes.Brands.path} className="d-sm-none d-xl-inline">Start Your Search</Nav.Link>&nbsp;|
                                <Nav.Link as={Link} to={Routes.CompanySearch.path} className="d-sm-none d-xl-inline">Suppliers</Nav.Link>&nbsp;|
                                <Nav.Link as={Link} to={Routes.ProductList.path} className="d-sm-none d-xl-inline">Products</Nav.Link>&nbsp;|
                                <Nav.Link as={Link} to={Routes.ProductServices.path}>Products & Services</Nav.Link>&nbsp;|
                                <Nav.Link as={Link} to={Routes.Support.path}>Support</Nav.Link>&nbsp;
                            </Nav>
                        </Navbar.Collapse>
                        <div style={{ backgroundColor: "#DA6220", margin: "0px", borderRadius: "0px" }} className="btn btn-group px-4 py-4">
                            <Button as={Link} to={Routes.Signup.path} variant="outline-none" className="text-white ms-3">Register</Button>
                            <Button as={Link} to={Routes.Signin.path} variant="outline-none" className="text-white ms-3 px-4">Login</Button>
                        </div>
                    </div>
                </Container>
            </Navbar>

            {/* Custom Off-canvas Menu for Mobile */}
            <div className={`offcanvas-menu bg-light ${showOffcanvas ? 'show' : ''}`}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">Menu</h5>
                    <Button variant="link" className="text-dark text-2xl p-0" onClick={toggleOffcanvas}>
                        &times;
                    </Button>
                </div>
                <div className="offcanvas-body">
                    <Nav className="flex-column text-center">
                        <Nav.Link as={Link} to={Routes.About.path} onClick={toggleOffcanvas}>About</Nav.Link>
                        <Nav.Link as={Link} to={Routes.Insights.path} onClick={toggleOffcanvas}>Insights</Nav.Link>
                        <Nav.Link as={Link} to={Routes.Careers.path} onClick={toggleOffcanvas}>Careers</Nav.Link>
                        <Nav.Link as={Link} to={Routes.FrequentlyAsked.path} onClick={toggleOffcanvas}>FAQ</Nav.Link>
                        <Nav.Link as={Link} to={Routes.Insights.path} onClick={toggleOffcanvas}>Insights</Nav.Link>
                        <Nav.Link as={Link} to={Routes.ForSuppliers.path} onClick={toggleOffcanvas}>For Suppliers</Nav.Link>
                    </Nav>
                    {!signedIn && (
                        <div className="mt-4 text-center">
                            <Button as={Link} to={Routes.Signup.path} variant="primary" className="mb-2 w-100">Register</Button>
                            <Button as={Link} to={Routes.Signin.path} variant="outline-primary" className="w-100">Login</Button>
                        </div>
                    )}
                </div>
            </div>

            {/* Overlay when Off-canvas is open */}
            {showOffcanvas && <div className="offcanvas-backdrop" onClick={toggleOffcanvas}></div>}
        </>
    );
};
