import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faIdCard, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { registerUser } from "../../../api/private/users";
import { createCompanies } from "../../../api/private/companies";
import { Carousel, Col, Row, Form, Card, Button, Container, InputGroup, ToggleButton, ButtonGroup, Table } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from "../../../routes";
import { ToastContainer, toast } from "react-toastify";
import { getAllPackagesPublic } from "../../../api/private/packages";
import { createTransactions, initiatePayment } from '../../../api/private/transactions';

const SignupWizard = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [personalDetails, setPersonalDetails] = useState({
    firstName: 'test',
    lastName: 'test',
    email: 'test@test.co.za',
    password: '5fe4r2wr3r124',
    accountType: 'seller',
    role: 'seller',
    approved: ''
  });
  const [companyDetails, setCompanyDetails] = useState({
    legal_name: 'test',
    trading_name: 'test',
    registration_number: '0000000000',
    vat_number: '0000000000',
    contact_number: '0000000000',
    primary_email: 'test@test.co.za',
    registered_address: '1 main rd, cpt',
    billing_address: '1 main rd, cpt',
    social_links: {
      linkedin: 'https://test.co.za',
      facebook: 'https://test.co.za',
      instagram: 'https://test.co.za',
      twitter: 'https://test.co.za',
    }
  });
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isAnnual, setIsAnnual] = useState(false);
  const [addons, setAddons] = useState({
    addonCompanies: 0,
    addonBrands: 0,
    addonProducts: 0,
    addonUsers: 0,
    addonChats: 0,
  });
  const [paymentMethod, setPaymentMethod] = useState('payfast');
  const history = useHistory();

  useEffect(() => {
    if (currentStep === 3) {
      loadPackages();
    }
  }, [currentStep]);

  useEffect(() => {
    if (selectedPackage) {
      // Reset add-ons when switching between monthly/annual tabs
      setAddons({
        addonCompanies: 0,
        addonBrands: 0,
        addonProducts: 0,
        addonUsers: 0,
        addonChats: 0,
      });
    }
  }, [isAnnual, selectedPackage]);

  function loadPackages() {
    getAllPackagesPublic().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        setPackages(records);
        if (records.length > 0) {
          setSelectedPackage(records[0]._id); // Select the first package by default
        }
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to load packages');
    });
  }

  const handleNext = () => setCurrentStep(currentStep + 1);
  const handlePrevious = () => setCurrentStep(currentStep - 1);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentStep === 1) {
      if (personalDetails.accountType === 'seller') {
        handleNext();
      } else {
        createUserAcc(personalDetails);
      }
    } else if (currentStep === 2 || currentStep === 3 || currentStep === 4) {
      handleNext();
    } else if (currentStep === 5) {
      processPayment();  // Finalize payment
    }
  };

  function createUserAcc(personalDetails, companyDetails) {
    registerUser(personalDetails.firstName, personalDetails.lastName, personalDetails.email, personalDetails.mobileNumber, personalDetails.password, personalDetails.role, personalDetails.approved)
      .then((userResponse) => {
        if (userResponse.data.Result === 'OK' || userResponse.data.Result === 'SUCCESS') {
          toast.success('User created successfully');

          const companyData = {
            legal_name: companyDetails.legal_name,
            trading_name: companyDetails.trading_name,
            registration_number: companyDetails.registration_number,
            vat_number: companyDetails.vat_number,
            location: {
              address: companyDetails.registered_address,
              area_code: ""
            },
            industry_type: "Undefined",
            contact_number: companyDetails.contact_number,
            primary_email: companyDetails.primary_email,
            registered_address: companyDetails.registered_address,
            billing_address: companyDetails.billing_address,
            company_type: "",
            company_bio: "",
            social_links: companyDetails.social_links,
            bbbee: {
              level: "",
              expiry_date: "",
              certificate: ""
            },
            created_by: {
              email: personalDetails.email,
              doc_id: userResponse.data.user_id,
            },
            brands: [],
            website_url: "",
            dealer_network: false,
            approved: false
          };

          return createCompanies(companyData).then((companyResponse) => {
            if (companyResponse.data.Result === 'OK' || companyResponse.data.Result === 'SUCCESS') {
              toast.success('Company created successfully');
            } else {
              toast.error(`Company not created, ${companyResponse.data.Message}`);
            }
          });
        } else {
          toast.error(`User not created, ${userResponse.data.Message}`);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('An error occurred during the registration process.');
      });
  }

  const processPayment = () => {
    const selectedPackageData = packages.find(pkg => pkg._id === selectedPackage);
    const price = isAnnual
      ? parseFloat(selectedPackageData.annual_price || 0)
      : parseFloat(selectedPackageData.monthly_price || 0);

    const addonCost = (
      (addons.addonCompanies * (isAnnual ? parseFloat(selectedPackageData.addon_companies_annual_price || 0) : parseFloat(selectedPackageData.addon_companies_price || 0))) +
      (addons.addonBrands * (isAnnual ? parseFloat(selectedPackageData.addon_brands_annual_price || 0) : parseFloat(selectedPackageData.addon_brands_price || 0))) +
      (addons.addonProducts * (isAnnual ? parseFloat(selectedPackageData.addon_products_annual_price || 0) : parseFloat(selectedPackageData.addon_products_price || 0))) +
      (addons.addonUsers * (isAnnual ? parseFloat(selectedPackageData.addon_users_annual_price || 0) : parseFloat(selectedPackageData.addon_users_price || 0))) +
      (addons.addonChats * (isAnnual ? parseFloat(selectedPackageData.addon_chats_annual_price || 0) : parseFloat(selectedPackageData.addon_chats_price || 0)))
    );

    const totalPrice = price + addonCost;

    const payfastData = {
      merchant_id: "12019827", // PayFast Merchant ID
      merchant_key: "xf5jyjwxxjh6e", // PayFast Merchant Key
      amount: totalPrice,
      item_name: `${selectedPackageData.title} with Add-ons`,
      return_url: "http://localhost:3000/signup-success",
      cancel_url: "http://localhost:3000/signup-cancel",
      notify_url: "http://localhost:3000/payfast-notify",
      email_address: personalDetails.email
    };

    // Create a form dynamically to submit to PayFast
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', 'https://sandbox.payfast.co.za/eng/process');
    form.style.display = 'none';

    // Add the PayFast parameters as hidden inputs
    for (const key in payfastData) {
      if (payfastData.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', payfastData[key]);
        form.appendChild(hiddenField);
      }
    }

    // Append the form to the document and submit it
    document.body.appendChild(form);
    form.submit();
  };

  // Function to calculate the total for addons
  const calculateAddonTotal = (addonQty, addonPrice) => addonQty * addonPrice || 0;

  const AddonsSelectionForm = ({ selectedPackageData, addons, setAddons, isAnnual }) => {
    const addonItems = [
      { title: 'Addon Companies', key: 'addonCompanies', price: isAnnual ? selectedPackageData.addon_companies_annual_price : selectedPackageData.addon_companies_price },
      { title: 'Addon Brands', key: 'addonBrands', price: isAnnual ? selectedPackageData.addon_brands_annual_price : selectedPackageData.addon_brands_price },
      { title: 'Addon Products', key: 'addonProducts', price: isAnnual ? selectedPackageData.addon_products_annual_price : selectedPackageData.addon_products_price },
      { title: 'Addon Users', key: 'addonUsers', price: isAnnual ? selectedPackageData.addon_users_annual_price : selectedPackageData.addon_users_price },
      { title: 'Addon Chats', key: 'addonChats', price: isAnnual ? selectedPackageData.addon_chats_annual_price : selectedPackageData.addon_chats_price },
    ];

    const subtotal = addonItems.reduce((acc, addon) => acc + calculateAddonTotal(addons[addon.key], addon.price), 0);

    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Addon</th>
              <th>Price per Unit</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {addonItems.map((addon, index) => (
              <tr key={index}>
                <td>{addon.title}</td>
                <td>R{addon.price}</td>
                <td>
                  <Form.Control
                    type="number"
                    value={addons[addon.key]}
                    onChange={e => setAddons({ ...addons, [addon.key]: parseInt(e.target.value) || 0 })}
                    min="0"
                  />
                </td>
                <td>R{calculateAddonTotal(addons[addon.key], addon.price)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md={6} className="mt-3">
            <h5>Subtotal for Addons: R{subtotal}</h5>
          </Col>
        </Row>
      </>
    );
  };

  const PackageSelectionForm = ({ packages, selectedPackage, setSelectedPackage, isAnnual, setIsAnnual }) => {
    const handleSelect = (selectedIndex) => {
      const selectedPkg = packages[selectedIndex];
      if (selectedPkg && selectedPkg._id) {
        setSelectedPackage(selectedPkg._id);
      }
    };

    const filteredPackages = packages.filter(pkg => (isAnnual ? pkg.annual_price : pkg.monthly_price));

    return (
      <Row>
        <Col md={12} className="text-center mb-4">
          <ButtonGroup toggle>
            <ToggleButton
              type="radio"
              variant="outline-primary"
              name="radio"
              value={false}
              checked={!isAnnual}
              onChange={() => setIsAnnual(false)}
            >
              Monthly Plans
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="outline-primary"
              name="radio"
              value={true}
              checked={isAnnual}
              onChange={() => setIsAnnual(true)}
            >
              Annual Plans
            </ToggleButton>
          </ButtonGroup>
        </Col>
        <Col md={12}>
          <Form.Group id="package_selection" className="mb-4">
            <Form.Label>Select a Package</Form.Label>
            {filteredPackages.length > 0 ? (
              <Carousel
                onSelect={handleSelect}
                interval={null}
                indicators={false}
                controls={true}
              >
                {filteredPackages.map((pkg, index) => (
                  <Carousel.Item key={pkg._id}>
                    <Card className={`p-3 ${selectedPackage === pkg._id ? 'border-primary' : ''}`}>
                      <Card.Body>
                        <Card.Title>{pkg.title}</Card.Title>
                        <Card.Text>
                          <strong>{isAnnual ? "Annual Price" : "Monthly Price"}: </strong>
                          R{isAnnual ? pkg.annual_price : pkg.monthly_price}
                          <br />
                          <strong>Features:</strong>
                          <ul>
                            {pkg.features.map((feature, index) => (
                              <li key={index}>{feature.text}</li>
                            ))}
                          </ul>
                        </Card.Text>
                        <Button
                          variant={selectedPackage === pkg._id ? 'primary' : 'outline-primary'}
                          onClick={() => setSelectedPackage(pkg._id)}
                        >
                          {selectedPackage === pkg._id ? 'Selected' : 'Select'}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <p>No packages available at the moment.</p>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const calculateTotalPrice = () => {
    const selectedPackageData = packages.find(pkg => pkg._id === selectedPackage);
    const packagePrice = isAnnual
      ? parseFloat(selectedPackageData.annual_price || 0)
      : parseFloat(selectedPackageData.monthly_price || 0);

    const addonCost = Object.keys(addons).reduce((total, key) => {
      const addonQty = addons[key];
      const addonPrice = selectedPackageData[`${key}_price`];
      return total + calculateAddonTotal(addonQty, addonPrice);
    }, 0);

    return packagePrice + addonCost;
  };

  return (
    <main className="bg-primary min-vh-100">
      <ToastContainer hideProgressBar="false" />
      <section className="d-flex bg-primary align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center bg-primary">
            <Col xs={12} lg={9} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                {personalDetails.accountType === 'seller' && (
                  <div className="wizard-nav d-flex justify-content-center mb-4">
                    <ul className="nav nav-pills">
                      <li className={`nav-item ${currentStep === 1 ? 'active' : ''}`}>
                        <span className="nav-link bg-primary text-white">1</span>
                      </li>
                      <li className={`nav-item ${currentStep === 2 ? 'active' : ''}`}>
                        <span className="nav-link bg-primary text-white">2</span>
                      </li>
                      <li className={`nav-item ${currentStep === 3 ? 'active' : ''}`}>
                        <span className="nav-link bg-primary text-white">3</span>
                      </li>
                      <li className={`nav-item ${currentStep === 4 ? 'active' : ''}`}>
                        <span className="nav-link bg-primary text-white">4</span>
                      </li>
                      <li className={`nav-item ${currentStep === 5 ? 'active' : ''}`}>
                        <span className="nav-link bg-primary text-white">5</span>
                      </li>
                    </ul>
                  </div>
                )}
                <Form className="mt-4" onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <PersonalDetailsForm
                      personalDetails={personalDetails}
                      setPersonalDetails={setPersonalDetails}
                    />
                  )}
                  {currentStep === 2 && personalDetails.accountType === 'seller' && (
                    <CompanyDetailsForm
                      companyDetails={companyDetails}
                      setCompanyDetails={setCompanyDetails}
                    />
                  )}
                  {currentStep === 3 && personalDetails.accountType === 'seller' && (
                    <PackageSelectionForm
                      packages={packages}
                      selectedPackage={selectedPackage}
                      setSelectedPackage={setSelectedPackage}
                      isAnnual={isAnnual}
                      setIsAnnual={setIsAnnual}
                    />
                  )}

                  {currentStep === 4 && personalDetails.accountType === 'seller' && (
                    <>
                      {selectedPackage && (
                        <>
                          <AddonsSelectionForm
                            selectedPackageData={packages.find(pkg => pkg._id === selectedPackage)}
                            addons={addons}
                            setAddons={setAddons}
                            isAnnual={isAnnual}
                          />
                          <h5>
                            Package Cost: R
                            {packages.find(pkg => pkg._id === selectedPackage)
                              ? packages.find(pkg => pkg._id === selectedPackage)[isAnnual ? 'annual_price' : 'monthly_price']
                              : 0}
                          </h5>
                        </>
                      )}
                    </>
                  )}

                  {currentStep === 5 && personalDetails.accountType === 'seller' && (
                    <div>
                      <h5>Review Your Selections</h5>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Package</th>
                            <th>{isAnnual ? "Annual Price" : "Monthly Price"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{packages.find(pkg => pkg._id === selectedPackage)?.title}</td>
                            <td>
                              R{packages.find(pkg => pkg._id === selectedPackage)[isAnnual ? 'annual_price' : 'monthly_price'] || 0}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <h5>Selected Add-ons</h5>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Addon</th>
                            <th>Quantity</th>
                            <th>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(addons).map((key) => {
                            const addonName = key.replace('addon', ''); // Simplify the key name
                            const addonQty = addons[key];

                            // Choose the correct price based on isAnnual flag
                            const addonPrice = isAnnual
                              ? packages.find(pkg => pkg._id === selectedPackage)?.[`${key}_annual_price`] || 0 // Annual add-on price
                              : packages.find(pkg => pkg._id === selectedPackage)?.[`${key}_price`] || 0;       // Monthly add-on price

                            if (addonQty > 0) {
                              const totalAddonPrice = addonQty * addonPrice;  // Multiply by quantity
                              return (
                                <tr key={key}>
                                  <td>{addonName}</td>
                                  <td>{addonQty}</td>
                                  <td>R{totalAddonPrice}</td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </Table>

                      <h5>Select Payment Method</h5>
                      <Card className="p-3">
                        <Form.Check
                          type="radio"
                          label="PayFast"
                          name="paymentMethod"
                          id="payfast"
                          value="payfast"
                          checked={paymentMethod === 'payfast'}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <Form.Check
                          type="radio"
                          label="Another Payment Method"
                          name="paymentMethod"
                          id="other"
                          value="other"
                          checked={paymentMethod === 'other'}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                      </Card>

                      <h5>Total Price: R{calculateTotalPrice()}</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-between">
                    {currentStep > 1 && personalDetails.accountType === 'seller' && (
                      <Button variant="secondary" onClick={handlePrevious}>
                        Previous
                      </Button>
                    )}
                    <Button variant="primary" type="submit">
                      {currentStep === 5 && personalDetails.accountType === 'seller'
                        ? 'Sign Up Now'
                        : personalDetails.accountType === 'buyer'
                          ? 'Submit'
                          : 'Next'}
                    </Button>

                  </div>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

const PersonalDetailsForm = ({ personalDetails, setPersonalDetails }) => (
  <Row>
    <Col md={12}>
      <Form.Group id="account" className="mb-4">
        <Form.Label>Account Type</Form.Label>
        <InputGroup>
          <Form.Control
            as="select"
            required
            value={personalDetails.accountType}
            onChange={e => {
              const selectedAccountType = e.target.value;
              let role = "";

              if (selectedAccountType === "buyer") {
                role = "buyer";
              } else if (selectedAccountType === "seller") {
                role = "seller";
              }

              setPersonalDetails({
                ...personalDetails,
                accountType: selectedAccountType,
                role: role
              });
            }}
          >
            <option value="">Select Account Type</option>
            <option value="buyer">Buyer</option>
            <option value="seller">Seller</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="first_name" className="mb-4">
        <Form.Label>Your First Name</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faIdCard} />
          </InputGroup.Text>
          <Form.Control
            autoFocus
            name="first_name"
            required
            type="text"
            placeholder="John"
            value={personalDetails.firstName}
            onChange={e => setPersonalDetails({ ...personalDetails, firstName: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="last_name" className="mb-4">
        <Form.Label>Your Last Name</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faIdCard} />
          </InputGroup.Text>
          <Form.Control
            required
            name="last_name"
            type="text"
            placeholder="Doe"
            value={personalDetails.lastName}
            onChange={e => setPersonalDetails({ ...personalDetails, lastName: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="email_address" className="mb-4">
        <Form.Label>Your Email</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faEnvelope} />
          </InputGroup.Text>
          <Form.Control
            autoFocus
            name="email"
            required
            type="email"
            placeholder="example@company.com"
            value={personalDetails.email}
            onChange={e => setPersonalDetails({ ...personalDetails, email: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="password" className="mb-4">
        <Form.Label>Your Password</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faUnlockAlt} />
          </InputGroup.Text>
          <Form.Control
            required
            type="password"
            name="password"
            placeholder="Password"
            value={personalDetails.password}
            onChange={e => setPersonalDetails({ ...personalDetails, password: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
  </Row >
);

const CompanyDetailsForm = ({ companyDetails, setCompanyDetails }) => (
  <Row>
    <Col md={6}>
      <Form.Group id="legal_name" className="mb-4">
        <Form.Label>Company Legal Name</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            placeholder="Company Legal Name"
            value={companyDetails.legal_name}
            onChange={e => setCompanyDetails({ ...companyDetails, legal_name: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="trading_name" className="mb-4">
        <Form.Label>Trading Name</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            placeholder="Trading Name"
            value={companyDetails.trading_name}
            onChange={e => setCompanyDetails({ ...companyDetails, trading_name: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="reg_number" className="mb-4">
        <Form.Label>Registration Number</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            placeholder="Registration Number"
            value={companyDetails.registration_number}
            onChange={e => setCompanyDetails({ ...companyDetails, registration_number: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="vat_number" className="mb-4">
        <Form.Label>VAT Number</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            placeholder="VAT Number"
            value={companyDetails.vat_number}
            onChange={e => setCompanyDetails({ ...companyDetails, vat_number: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="contact_number" className="mb-4">
        <Form.Label>Primary Contact Number</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            placeholder="Primary Contact Number"
            value={companyDetails.contact_number}
            onChange={e => setCompanyDetails({ ...companyDetails, contact_number: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="primary_email" className="mb-4">
        <Form.Label>Primary Contact Email</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="email"
            placeholder="Primary Contact Email"
            value={companyDetails.primary_email}
            onChange={e => setCompanyDetails({ ...companyDetails, primary_email: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="registered_address" className="mb-4">
        <Form.Label>Registered Address</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            placeholder="Registered Address"
            value={companyDetails.registered_address}
            onChange={e => setCompanyDetails({ ...companyDetails, registered_address: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="billing_address" className="mb-4">
        <Form.Label>Billing Address</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            placeholder="Billing Address"
            value={companyDetails.billing_address}
            onChange={e => setCompanyDetails({ ...companyDetails, billing_address: e.target.value })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    {/* Social Links */}
    <Col md={6}>
      <Form.Group id="linkedin" className="mb-4">
        <Form.Label>LinkedIn</Form.Label>
        <InputGroup>
          <Form.Control
            type="url"
            placeholder="LinkedIn Profile URL"
            value={companyDetails.social_links.linkedin}
            onChange={e => setCompanyDetails({
              ...companyDetails,
              social_links: { ...companyDetails.social_links, linkedin: e.target.value }
            })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="facebook" className="mb-4">
        <Form.Label>Facebook</Form.Label>
        <InputGroup>
          <Form.Control
            type="url"
            placeholder="Facebook Profile URL"
            value={companyDetails.social_links.facebook}
            onChange={e => setCompanyDetails({
              ...companyDetails,
              social_links: { ...companyDetails.social_links, facebook: e.target.value }
            })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="instagram" className="mb-4">
        <Form.Label>Instagram</Form.Label>
        <InputGroup>
          <Form.Control
            type="url"
            placeholder="Instagram Profile URL"
            value={companyDetails.social_links.instagram}
            onChange={e => setCompanyDetails({
              ...companyDetails,
              social_links: { ...companyDetails.social_links, instagram: e.target.value }
            })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group id="twitter" className="mb-4">
        <Form.Label>Twitter</Form.Label>
        <InputGroup>
          <Form.Control
            type="url"
            placeholder="Twitter Profile URL"
            value={companyDetails.social_links.twitter}
            onChange={e => setCompanyDetails({
              ...companyDetails,
              social_links: { ...companyDetails.social_links, twitter: e.target.value }
            })}
          />
        </InputGroup>
      </Form.Group>
    </Col>
  </Row>
);

export default SignupWizard;
