import axios from "axios";
import { api_token_key } from "../../utils/constants";
import localSessionApi from "../../utils/constants";

const PROXY_ENDPOINT = process.env.REACT_APP_PROXY_ENDPOINT;

// Function to handle public POST requests
export function doPublicPostRequest(_payload, _path) {
    const PROXY_TOKEN = localSessionApi.getWithExpiry(api_token_key);

    // Log token for debugging
    console.log("Proxy token is", PROXY_TOKEN);

    // If the token is not found, omit the 'FLC-JWT' header for public requests
    const headers = {
        'Content-Type': 'application/json',
    };

    if (PROXY_TOKEN) {
        headers['FLC-JWT'] = PROXY_TOKEN;
    }

    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        headers: headers,
        data: _payload
    };

    return axios(config);
}

// Function to handle public GET requests
export function doPublicGetRequest(_path) {
    const PROXY_TOKEN = localSessionApi.getWithExpiry(api_token_key);

    // Log token for debugging
    console.log("Proxy token is", PROXY_TOKEN);

    // If the token is not found, omit the 'FLC-JWT' header for public requests
    const headers = {
        'Content-Type': 'application/json',
    };

    if (PROXY_TOKEN) {
        headers['FLC-JWT'] = PROXY_TOKEN;
    }

    const config = {
        method: 'get',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        headers: headers
    };

    return axios(config);
}