import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createCompanies = (payload) => {
    return doPrivatePostRequest(payload, '/companies/create')
}

export const updateCompanies = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `companies/update/${_doc_id}`)
}

export const getCompanies = (_doc_id) => {
    return doPrivateGetRequest(`companies/get/${_doc_id}`)
}

export const getAllCompanies = () => {
    return doPrivateGetRequest('companies')
}

export const deleteCompanies = (_doc_id) => {
    return doPrivateDelRequest(`companies/delete/${_doc_id}`)
}
