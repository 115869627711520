import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createQuotes = (payload) => {
    return doPrivatePostRequest(payload, 'quotes/create')
}

export const updateQuotes = (payload) => {
    return doPrivatePostRequest(payload, 'quotes/update')
}

export const getAllQuotes = () => {
    return doPrivateGetRequest('quotes')
}

export const deleteQuotes = (_doc_id) => {
    return doPrivateDelRequest(`quotes/delete/${_doc_id}`)
}
