import React from "react";
import { Modal, Button, Table } from "@themesberg/react-bootstrap";

const TransactionDetailsModal = ({ show, onClose, transaction, downloadInvoice }) => {
  if (!transaction) return null;

  const {
    _id,
    user_id,
    package_id,
    package_name,
    is_annual,
    amount,
    addons,
    total_addons_price,
    package_price,
    currency,
    transaction_date,
    payment_status,
    payment_gateway,
    payment_gateway_response,
  } = transaction;

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Customer and Transaction Details */}
        <div className="mb-4">
          <h5>Customer & Transaction Details</h5>
          <div className="d-flex justify-content-between">
            <div>
              <p><strong>Transaction ID:</strong> {_id}</p>
              <p><strong>User ID:</strong> {user_id}</p>
              <p><strong>Package Name:</strong> {package_name}</p>
              <p><strong>Package ID:</strong> {package_id}</p>
              <p><strong>Is Annual:</strong> {is_annual ? "Yes" : "No"}</p>
            </div>
            <div>
              <p><strong>Transaction Date:</strong> {transaction_date}</p>
              <p><strong>Amount:</strong> {currency} {amount}</p>
              <p><strong>Total Add-ons Price:</strong> {currency} {total_addons_price}</p>
              <p><strong>Package Price:</strong> {currency} {package_price}</p>
              <p><strong>Payment Status:</strong> {payment_status}</p>
            </div>
          </div>
        </div>

        {/* Add-ons Purchase Information */}
        <div className="mb-4">
          <h5>Purchase Information</h5>
          <Table responsive className="table-nowrap mb-0">
            <thead>
              <tr>
                <th>Addon Type</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(addons).map((addonKey) => (
                <tr key={addonKey}>
                  <td>{addonKey}</td>
                  <td>{addons[addonKey]?.quantity}</td>
                  <td>{currency} {addons[addonKey]?.unit_price}</td>
                  <td>{currency} {addons[addonKey]?.total_price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Payment Gateway Details */}
        <div className="mb-4">
          <h5>Payment Gateway Information</h5>
          <p><strong>Gateway:</strong> {payment_gateway}</p>
          <Table responsive className="table-nowrap mb-0">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Payment URL</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{payment_gateway_response?.transaction_id}</td>
                <td>{payment_gateway_response?.payment_url}</td>
                <td>{payment_gateway_response?.status}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={() => downloadInvoice(transaction)}>Download Invoice</Button> {/* Download button */}
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionDetailsModal;
