import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Dropdown } from '@themesberg/react-bootstrap';
import AddCompaniesModal from "../../../components/modals/AddCompaniesModal";
import { getAllCompanies, createCompanies, deleteCompanies, updateCompanies } from "../../../api/private/companies";
import { getAllOfferings, createOfferings, updateOfferings, deleteOfferings } from "../../../api/private/offerings";
import { createSuppliers } from "../../../api/private/suppliers";
import { getAllBrands } from '../../../api/private/brands';
import { CompaniesTables } from "../../../components/tables/CompaniesTables";
import { makeToast } from "../../../functions/toasts";
import AlertComponent from "../../../functions/alerts";
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast} from "react-toastify";

export default () => {
  const { userRole } = useUserRole();
  const [companies, setCompanies] = useState([]);
  const [initialCompanyData, setInitialCompanyData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [offerings, setOfferings] = useState([]);
  const [initialOfferingData, setInitialOfferingData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pageMounted, setPageMounted] = useState(false);
  const [brands, setBrands] = useState({});
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (!pageMounted) {
      setPageMounted(true);
      loadCompanies();
      loadOfferings();
      loadBrands();
    }
  }, [pageMounted]);

  function loadOfferings() {
    getAllOfferings().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        toast.success('Successfully loaded offerings');
        setOfferings(records);
      } else {
        console.warn(_resp.data);
        toast.error('Failed to load offerings');
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to load offerings');
    });
  }

  function createOffering(offeringData) {
    const payload = {
      'offering': offeringData['offering'],
      'offering_content': offeringData['offering_content'],
      'rental_service': offeringData['rental_service'],
      'maintenance_and_repairs': offeringData['maintenance_and_repairs'],
      'training_programmings': offeringData['training_programmings'],
      'spare_parts_and_accessories': offeringData['spare_parts_and_accessories'],
      'emergency_services_and_response': offeringData['emergency_services_and_response'],
      'technology_solutions': offeringData['technology_solutions'],
      'refurbished_solutions': offeringData['refurbished_solutions'],
      'customized_solutions': offeringData['customized_solutions'],
      'compliance_and_certification_services': offeringData['compliance_and_certification_services'],
      'warranty_offered': offeringData['warranty_offered'],
      'notes': offeringData['notes']
    };

    createOfferings(payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        toast.success('Created new offering');
        handleCloseModal();
        loadOfferings();
      } else {
        console.warn(_resp.data);
        toast.error('Failed to create offering');
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to create offering');
    });
  }

  function loadBrands() {
    getAllBrands().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        toast.success('Successfully loaded brands');
        const records = _resp.data.Records;
        setBrands(records);
      } else {
        console.warn(_resp.data);
        toast.error('Failed to load brands');
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to load brands');
    });
  }

  function updateOffering(offeringData) {
    const payload = {
      'id': offeringData['_id'],
      'offering': offeringData['offering'],
      'offering_content': offeringData['offering_content'],
      'rental_service': offeringData['rental_service'],
      'maintenance_and_repairs': offeringData['maintenance_and_repairs'],
      'training_programmings': offeringData['training_programmings'],
      'spare_parts_and_accessories': offeringData['spare_parts_and_accessories'],
      'emergency_services_and_response': offeringData['emergency_services_and_response'],
      'technology_solutions': offeringData['technology_solutions'],
      'refurbished_solutions': offeringData['refurbished_solutions'],
      'customized_solutions': offeringData['customized_solutions'],
      'compliance_and_certification_services': offeringData['compliance_and_certification_services'],
      'warranty_offered': offeringData['warranty_offered'],
      'notes': offeringData['notes']
    };

    updateOfferings(payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        toast.success('Offering updated successfully');
        handleCloseModal();
        loadOfferings();
      } else {
        console.warn(_resp.data);
        toast.error('Failed to update offering');
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to update offering');
    });
  }

  function deleteOffering(offerings) {
    deleteOfferings(offerings).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        toast.success('Deleted offering');
        loadOfferings();
      } else {
        console.warn(_resp.data);
        toast.error('Failed to delete offering');
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to delete offering');
    });
  }

  const handleEditOffering = (offering) => {
    setInitialOfferingData(offering);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddOffering = () => {
    setInitialOfferingData(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  function loadCompanies() {
    getAllCompanies().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        setCompanies(records);
        toast.success(`Loaded companies successfully`);
      } else {
        console.warn(_resp.data);
        toast.error(`Failed to load companies. Please contact support.`);
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error(`Failed to load companies. Please contact support.`);
    });
  }

  function createCompAcc(addedCompanyData) {
    const payload = {
      "legal_name": addedCompanyData['legal_name'],
      "trading_name": addedCompanyData['trading_name'],
      "registration_number": addedCompanyData['registration_number'],
      "vat_number": addedCompanyData['vat_number'],
      "contact_number": addedCompanyData['contact_number'],
      "primary_email": addedCompanyData['primary_email'],
      "social_links": {
        "linkedin": addedCompanyData['social_links']['linkedin'],
        "facebook": addedCompanyData['social_links']['facebook'],
        "instagram": addedCompanyData['social_links']['instagram'],
        "twitter": addedCompanyData['social_links']['twitter']
      },
      "dealer_network": addedCompanyData['dealer_network'],
      "registered_address": addedCompanyData['registered_address'],
      "billing_address": addedCompanyData['billing_address'],
      "industry_type": addedCompanyData['industry_type'],
      "location": {
        "address": addedCompanyData['location']['address'],
        "area_code": addedCompanyData['location']['area_code']
      },
      "website_url": addedCompanyData['website_url'],
      "bbbee": {
        "level": addedCompanyData['bbbee']['level'],
        "expiry_date": addedCompanyData['bbbee']['expiry_date'],
        "certificate": addedCompanyData['bbbee']['certificate']
      },
      "brands": addedCompanyData['brands'],
      "company_type": addedCompanyData['company_type'],
      "company_logo": addedCompanyData['company_logo'],
      "company_bio": addedCompanyData['company_bio']
    };

    createCompanies(payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        handleCloseModal();
        toast.success(`${_resp.data.Message}`);
        const newCompany = _resp.data.Record;
        if (newCompany) {
          const supplierPayload = {
            'linked_company': {
              'doc_id': newCompany._id,
              'name': newCompany.trading_name
            },
            'linked_user': [{
              'doc_id': 'ccaa4927f2094618b65337ad28e98b40',
              'name': 'Dummy User - No Login'
            }]
          };
          createSupplierAcc(supplierPayload);
        }
        loadCompanies();
      } else {
        console.warn(_resp.data);
        handleCloseModal();
        toast.error(`${_resp.data.Message}`);
        loadCompanies();
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error(`${_resp.data.Message}`);
    });
  }

  function updateCompAcc(updatedCompany) {
    const { doc_id, ...companyData } = updatedCompany;
    const payload = {
      "id": updatedCompany['_id'],
      "legal_name": updatedCompany['legal_name'],
      "trading_name": updatedCompany['trading_name'],
      "registration_number": updatedCompany['registration_number'],
      "vat_number": updatedCompany['vat_number'],
      "contact_number": updatedCompany['contact_number'],
      "primary_email": updatedCompany['primary_email'],
      "social_links": {
        "linkedin": updatedCompany['social_links']['linkedin'],
        "facebook": updatedCompany['social_links']['facebook'],
        "instagram": updatedCompany['social_links']['instagram'],
        "twitter": updatedCompany['social_links']['twitter']
      },
      "dealer_network": updatedCompany['dealer_network'],
      "registered_address": updatedCompany['registered_address'],
      "billing_address": updatedCompany['billing_address'],
      "industry_type": updatedCompany['industry_type'],
      "location": {
        "address": updatedCompany['location']['address'],
        "area_code": updatedCompany['location']['area_code']
      },
      "website_url": updatedCompany['website_url'],
      "bbbee": {
        "level": updatedCompany['bbbee']['level'],
        "expiry_date": updatedCompany['bbbee']['expiry_date'],
        "certificate": updatedCompany['bbbee']['certificate']
      },
      "brands": updatedCompany['brands'],
      "company_type": updatedCompany['company_type'],
      "company_logo": updatedCompany['company_logo'],
      "company_bio": updatedCompany['company_bio']
    };

    updateCompanies(doc_id, payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        handleCloseModal();
        const successMessage = _resp.data.Message ? _resp.data.Message : 'Company created successfully';
        toast.success(successMessage);
        loadCompanies();
      } else {
        console.warn(_resp.data);
        toast.error(`${_resp.data.Message}`);
        handleCloseModal();
        loadCompanies();
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error(`${_resp.data.Message}`)   
      handleCloseModal();
    });
  }

  function createSupplierAcc(payload) {
    createSuppliers(payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        toast.success('Created new supplier');
        setShowModal(false);
      } else {
        console.warn(_resp.data);
        toast.error('Failed to create supplier');
      }
    }).catch((_resp) => {
      console.error(_resp);
      toast.error('Failed to create supplier');
    });
  }

  const handleEditCompany = (company) => {
    setInitialCompanyData(company);
    setIsEditMode(true);
    setShowModal(true);
  };

  const deleteCompany = (companyId) => {
    deleteCompanies(companyId).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        toast.success('Successfully deleted company')
        setCompanies(companies.filter(company => company._id !== companyId));
        loadCompanies();
      } else {
        toast.error(`Error: ${_resp.data?.Message}`)
        loadCompanies();
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      setAlert({ type: 'error', message: `${_resp.data?.Message}` });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      console.error(_resp);
    });
  };

  return (
    <>
    <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Companies</Breadcrumb.Item>
            <Breadcrumb.Item active>Companies</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Companies</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleShowModal}>Add Companies</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}></Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />

      <CompaniesTables 
        offerings={offerings} 
        deleteOffering={deleteOffering} 
        handleEditOffering={handleEditOffering} 
        companies={companies} 
        deleteCompany={deleteCompany} 
        handleEditCompany={handleEditCompany} 
        isEditMode={isEditMode} 
      />
      <AddCompaniesModal 
        createOffering={createOffering}
        updateOffering={updateOffering} 
        brands={brands}
        initialOfferingData={initialOfferingData} 
        showModal={showModal} 
        handleCloseModal={handleCloseModal} 
        createCompAcc={createCompAcc} 
        updateCompAcc={updateCompAcc} 
        initialCompanyData={initialCompanyData}
        isEditMode={isEditMode} 
      />
    </>
  );
};
